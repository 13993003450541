import React, { FC, ReactElement } from 'react';
import { Text } from 'components';
import styled from 'styled-components/macro';
import IconButton from './IconButton';
import { FrontendEnvironment } from '../../../../../utils/config';
import { useEnvironment } from '../../../../../providers/Environment';

const IconsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const Title = styled(Text).attrs(() => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.4,
}))`
    padding-top: 4px;
    padding-left: 32px;
`;

export type IconData = {
  name: string;
  path: string;
}

export type GalleryIconData = {
  category: string;
  icons: IconData[];
}

export type ClipArtData = {
  name: string;
  base64: string | ArrayBuffer | null;
}

interface Props {
  category: string;
  icons: IconData[];
  setClipArt: (data: ClipArtData) => void;
  searchPhrase: string;
}

const IconContainer: FC<Props> = ({ category, icons, setClipArt, searchPhrase }): ReactElement => {
  const { frontendEnvironment } = useEnvironment();
  const iconBasePath = frontendEnvironment === FrontendEnvironment.Sandbox ? process.env.PUBLIC_URL : '';

  const lowerCaseSearch = searchPhrase.toLowerCase();
  const IconType = icons?.map((icon: IconData) => {
    const iconMatchesSearch = icon.name.toLowerCase().includes(lowerCaseSearch);
    const iconCategoryMatchesSearch = category.toLowerCase().includes(lowerCaseSearch);

    if (searchPhrase === '' || iconMatchesSearch || iconCategoryMatchesSearch) {
      return (
        <IconButton
          setClipArt={setClipArt}
          name={icon.name}
          path={`${iconBasePath}${icon.path}`}
          key={`icon-${icon.name}`}
        />
      );
    } else {
      return null;
    }
  });

  return (
    <IconsContainer>
      <InnerContainer>
        <Title>{category}</Title>
      </InnerContainer>
      <InnerContainer>{IconType}</InnerContainer>
    </IconsContainer>
  );
};

export default IconContainer;
