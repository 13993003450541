import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import IconContainer from './IconContainer';

const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  height: 350px;
`;

const IconCategoryContainer = ({ setClipArt, searchPhrase, data }) => {
  const iconCategories = data?.map((item) => {
    if (
      searchPhrase === '' ||
      item.category?.toLowerCase().includes(searchPhrase?.toLowerCase()) ||
      item.icons.some((e) =>
        e.name.toLowerCase().includes(searchPhrase?.toLowerCase()),
      )
    ) {
      return (
        <IconContainer
          setClipArt={setClipArt}
          icons={item.icons}
          category={item.category}
          searchPhrase={searchPhrase}
          key={`category-${item.category}`}
        />
      );
    } else {
      return <Fragment key={`category-${item.category}`}></Fragment>;
    }
  });

  return <CategoriesWrapper>{iconCategories}</CategoriesWrapper>;
};

export default IconCategoryContainer;
