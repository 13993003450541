import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Flex, Input, LanguageSwitch, Loader, Text } from 'components';
import { PasswordChange } from './PasswordChange';
import { ThemeContext } from 'styled-components/macro';
import { actions as authActions } from 'ducks/auth/actions';
import { actions as infoActions } from 'ducks/info/actions';
import {
  useAppDispatch,
  useDebounce,
  useModal,
  useReduxAction,
  useReduxSelector,
} from 'hooks';
import {
  getGoogleAccount,
  getGoogleAccountError,
  getGoogleAccountLoading,
} from 'ducks/dataSources/selectors';
import { getLinks } from 'ducks/info/selectors';
import { actions as datasourcesActions } from 'ducks/dataSources/actions';
import styled from 'styled-components';
import {
  getAccountLoading,
  getCustomer,
  getDeleteAccount,
  getUpdateFirstNameError,
  getUpdateFirstNameLoading,
  getUpdateFirstNameSuccess,
  getUpdateLastNameError,
  getUpdateLastNameLoading,
  getUpdateLastNameSuccess,
  getUser,
} from 'ducks/auth/selectors';
import { CustomerInfoType, State } from 'zsbpsdk/src/customer';
import { useMediaQuery } from 'react-responsive';
import UnitsComponent from './Units';
import { NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX } from 'services/api';
import EmailModal from './EmailModal';
import LogoutModal from './LogoutModal';
import { device } from '../../../utils/mediaQueries';
import { useTranslation } from 'react-i18next';
import { ZEBRA_EMAIL_EXTENSION } from 'utils/config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { openBasicToast } from 'state/Toast';
import DeleteAccountConfirmationModal from 'pages/overview/DeleteAccountConfirmationModal';
import {
  getDeleteAccountStarted,
  clearDeleteAccountStarted,
  isInitialDeleteAccountCustomer,
} from 'utils/deleteAccountStarted';
import DeleteAccountErrorModal from 'pages/overview/DeleteAccountErrorModal';
import DeleteAccountSuccessModal from 'pages/overview/DeleteAccountSuccessModal';

const ChangeButton = styled(Box).attrs(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 500,
  letterSpacing: 0.4,
  color: theme.primary.base,
}))`
  display: inline-block;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  text-align: left;

  &:hover {
    color: ${({ theme }) => theme.primary.dark};
  }
`;

const LabelText = styled(Text).attrs(({ theme }) => ({
  fontSize: 14,
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4',
  color: theme.neutral.darkest,
}))``;

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.base};
`;

export const DetailsForm = () => {
  const { t } = useTranslation('settings');

  const dispatch = useAppDispatch();

  const theme = React.useContext(ThemeContext);
  const getGoogleAccountAction = useReduxAction(
    datasourcesActions.GOOGLE_ACCOUNT.request,
  );
  const removeGoogleAccountAction = useReduxAction(
    datasourcesActions.GOOGLE_ACCOUNT.clear,
  );

  const updateFirstName = useReduxAction(authActions.UPDATE_FIRSTNAME.request);
  const updateLastName = useReduxAction(authActions.UPDATE_LASTNAME.request);
  const requestLinks = useReduxAction(infoActions.LINKS.request);
  const deleteAccountClear = useReduxAction(authActions.DELETE_ACCOUNT.clear);

  const user = useReduxSelector(getUser);
  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const googleAccount = useReduxSelector(getGoogleAccount);
  const googleAccountError = useReduxSelector(getGoogleAccountError);
  const googleAccountLoading = useReduxSelector(getGoogleAccountLoading);
  const accountLoading = useReduxSelector(getAccountLoading);
  const links = useReduxSelector(getLinks);
  const firstNameLoading = useReduxSelector(getUpdateFirstNameLoading);
  const lastNameLoading = useReduxSelector(getUpdateLastNameLoading);
  const firstNameError = useReduxSelector(getUpdateFirstNameError);
  const lastNameError = useReduxSelector(getUpdateLastNameError);
  const firstNameSuccess = useReduxSelector(getUpdateFirstNameSuccess);
  const lastNameSuccess = useReduxSelector(getUpdateLastNameSuccess);

  const { data: deleteAccountSuccessData, error: deleteAccountError } =
    useReduxSelector(getDeleteAccount);

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [isZebra] = useState(
    !!customer?.email && customer?.email.includes(ZEBRA_EMAIL_EXTENSION),
  );
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const emailModalRef = useRef(null);
  const logoutModalRef = useRef(null);
  const logoutModalHook = useModal(logoutModalRef);
  const emailModalHook = useModal(emailModalRef);

  const [logoutIsOpen, setLogoutIsOpen] = useState<boolean>(false);

  const debounceFirstName = useDebounce(firstName, 1000);
  const debounceLastName = useDebounce(lastName, 1000);

  const firstNameIsEmpty = (firstName.length <1 && (!firstNameLoading && !accountLoading));
  const lastNameIsEmpty = (lastName.length <1 && (!lastNameLoading && !accountLoading));

  const firstNameHasError = !isZebra && ((!!firstName && !!firstName.match(NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX)) || firstNameIsEmpty || firstName.length > 50);
  const lastNameHasError = !isZebra && ((!!lastName && !!lastName.match(NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX)) || lastNameIsEmpty || lastName.length > 50);

  const getFirstNameErrorMessage = () => {
    if(isZebra) return null;

    if(firstNameIsEmpty){
      return t('settings:name-length-at-least-one-character');
    }
    else if(firstName.match(NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX)){
      return t('settings:no-special-characters-allowed' )
    }
    else if (firstName.length > 50) {
      return t('settings:first-name-can-not-exceed-length');
    }
    else {
      return null;
    }
  };

  const getLastNameErrorMessage = () => {
    if(isZebra) return null;

    if(lastNameIsEmpty){
      return t('settings:name-length-at-least-one-character');
    }
    else if(lastName.match(NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX)){
      return t('settings:no-special-characters-allowed' )
    }
    else if (lastName.length > 50) {
      return t('settings:last-name-can-not-exceed-length');
    }
    else {
      return null;
    }
  };

  const isOpen =
    isInitialDeleteAccountCustomer(customer.id) && getDeleteAccountStarted();

  const [open, setOpen] = useState<boolean>(isOpen);

  const handleClose: () => void = () => {
    clearDeleteAccountStarted();
    setOpen(false);
  };

  useEffect(() => {
    if (firstNameSuccess && user && user.firstName) {
      setFirstName(user.firstName);
    }
    if (lastNameSuccess && user && user.lastName) {
      setLastName(user.lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstNameSuccess, lastNameSuccess]);

  useEffect(() => {
    if (
      firstName &&
      firstName !== user?.firstName &&
      firstName.length > 0 &&
      firstName.length < 51 &&
      !firstName.match(NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX) 
    ) {
      updateFirstName(firstName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceFirstName]);

  useEffect(() => {
    if (
      lastName &&
      lastName !== user?.lastName &&
      lastName.length > 0 &&
      lastName.length < 51 &&
      !lastName.match(NO_SPECIAL_CHARS_ALLOW_SPACES_REGEX)
    ) {
      updateLastName(lastName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceLastName]);

  useEffect(() => {
    if (lastNameError && user && user.lastName) setLastName(user.lastName);
    if (firstNameError && user && user.firstName) setFirstName(user.firstName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNameError, firstNameError]);

  useEffect(() => {
    if (!firstName && user && user.firstName) setFirstName(user.firstName);
    if (!lastName && user && user.lastName) setLastName(user.lastName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const isMobile = useMediaQuery({ query: device.mobile });

  const emailResolve = (hasChanged) => {
    if (hasChanged) {
      setLogoutIsOpen(true);
      logoutModalHook.open();
    }
  };

  useEffect(() => {
    getGoogleAccountAction();
    requestLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disconnectOneDriveAccount = useCallback(async () => {
    await instance.logout({
      onRedirectNavigate: () => false, // Return false if you would like to stop navigation after local logout
    });
    dispatch(
      openBasicToast(
        t('settings:account.successful-disconnect', {
          accountToRemove: accounts[0].username,
        }),
      ),
    );
  }, [dispatch, t, instance, accounts]);

  const removeGoogleAccount = () => {
    removeGoogleAccountAction();
    dispatch(
      openBasicToast(
        t('settings:account.successful-disconnect', {
          accountToRemove: googleAccount,
        }),
      ),
    );
  };

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'flex-start'}
      width={isMobile ? '100%' : 336}
      mr={11}
      mb={140}
    >
      {isZebra && <h3>{t('settings:accounts-cannot-be-edited')}</h3>}

      <Box mb={11}>
        <Text
          fontSize={14}
          fontWeight={400}
          color={theme.textColors.high}
          letterSpacing={0.4}
        >
          {t('settings:first-name')}
        </Text>
        <Box height="4px" />

        <Input
          type={'text'}
          value={firstName}
          disabled={isZebra || !user}
          hasError={firstNameHasError}
          message={getFirstNameErrorMessage()}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFirstName(e.target.value)
          }
          busy={firstNameLoading || accountLoading}
          data-testid={'first-name'}
        />
      </Box>
      <Box mb={11}>
        <Text
          fontSize={14}
          fontWeight={400}
          color={theme.textColors.high}
          letterSpacing={0.4}
        >
          {t('settings:last-name')}
        </Text>
        <Box height="4px" />

        <Input
          type={'text'}
          disabled={isZebra || !user}
          hasError={lastNameHasError}
          message={getLastNameErrorMessage()}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLastName(e.target.value)
          }
          busy={lastNameLoading || accountLoading}
          value={lastName}
          data-testid="last-name"
        />
      </Box>
      <Box mb={11}>
        <Text
          fontSize={14}
          fontWeight={400}
          color={theme.textColors.high}
          letterSpacing={0.4}
        >
          {t('settings:email')}
        </Text>
        <Box height="4px" />

        <Input
          data-testid="account-settings-email"
          type={'text'}
          value={customer?.email}
          disabled={true}
          busy={accountLoading}
        />

        {!isZebra && (
          <Box mt={4}>
            <ChangeButton
              data-testid="change-email-button"
              as={'button'}
              onClick={() => {
                // if (LinkedAccountString === null) {
                emailModalHook.open();
                // } else {
                //   toastTimed(
                //     'basic',
                //     `Email is linked to external${LinkedAccountString}account and can't be edited.`,
                //     2000,
                //   );
              }}
            >
              <Text>{t('settings:change-email')}</Text>
            </ChangeButton>
          </Box>
        )}
      </Box>
      <Box mb={11}>
        <PasswordChange />
        {/* TODO: Add 'English to this dropdown */}
        <LabelText>{t('settings:preferred-language')}</LabelText>
        <LanguageSwitch />
      </Box>
      <Box mb={11} width={'100%'}>
        <UnitsComponent />
      </Box>
      {isAuthenticated && accounts.length > 0 && (
        <Box mb={11}>
          <Input
            data-testid="microsoft-account-input"
            type={'text'}
            label={t('settings:account.microsoft')}
            value={accounts[0].username}
            disabled={true}
          />
          <ChangeButton
            data-testid="microsoft-account-disconnect-button"
            as={'button'}
            onClick={disconnectOneDriveAccount}
          >
            <Text>{t('settings:account.disconnect')}</Text>
          </ChangeButton>
        </Box>
      )}
      {googleAccountLoading ? (
        <Loader visible={true} />
      ) : (
        googleAccount &&
        !googleAccountError && (
          <Box mb={11}>
            <Input
              data-testid="google-account-input"
              type={'text'}
              label={t('settings:account.google')}
              value={googleAccount}
              disabled={true}
            ></Input>
            <ChangeButton
              data-testid="google-account-disconnect-button"
              as={'button'}
              onClick={removeGoogleAccount}
            >
              <Text>{t('settings:account.disconnect')}</Text>
            </ChangeButton>
          </Box>
        )
      )}
      <Box mb={11} mt={'0px'} ml={'0px'} width={'100%'}>
        <LabelText data-testid="privacy-policy-label">
          {`${t('settings:privacy-policy')}:`}
        </LabelText>
        <StyledLink
          data-testid="privacy-policy-review-button"
          href={links?.get('privacy')}
          target="_blank"
          rel="noopener"
        >
          {t('settings:privacy-policy-review')}
        </StyledLink>
      </Box>
      <Box mt={'0px'} ml={'0px'} width={'100%'}>
        <LabelText data-testid="customer-id-label">
          {`${t('settings:customer-id')}:`}
        </LabelText>
        <LabelText data-testid="customer-id-value">{customer.id}</LabelText>
      </Box>
      <EmailModal ref={emailModalRef} resolve={emailResolve} />
      <LogoutModal ref={logoutModalRef} isOpen={logoutIsOpen} />
      <DeleteAccountConfirmationModal
        open={open}
        handleModalClose={handleClose}
      />

      {deleteAccountError && deleteAccountError.state === State.CONFIRM && (
        <DeleteAccountErrorModal
          open={!!deleteAccountError}
          handleModalClose={deleteAccountClear}
        />
      )}
      {deleteAccountSuccessData &&
        deleteAccountSuccessData.state === State.CONFIRM && (
          <DeleteAccountSuccessModal
            open={!!deleteAccountSuccessData}
            handleModalClose={deleteAccountClear}
          />
        )}
    </Flex>
  );
};
